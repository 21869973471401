import axios from 'axios'
import { Toast,Dialog  } from 'vant'
import router from '../router'
import store from "../store"; // 引入
import { autoRegister} from '../service/user'
import { doCopy } from '../common/js/utils'
import {i18n} from '../lang'

axios.interceptors.request.use(
  config => {   //在请求发出之前进行一些操作
    let langVal = store.state["lang"]//computed(()=>store.getters["getLang"]).value
    let lang = langVal || localStorage['locale'] || 'th'
    if(lang == 'zh') lang = 'zhCN'
    config.headers['language'] = lang
    config.headers['token'] = localStorage['token']
    return config
  }
)

// axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://10.233.10.180:28020/api/v1' : 'https://api-dev-2.siweipay.com/api/v1'
// axios.defaults.baseURL = 'https://api.mustbuyit.cn/api/v1'
axios.defaults.baseURL = 'https://api-dev-2.siweipay.com/api/v1'
axios.defaults.withCredentials = true
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['token'] = localStorage.getItem('token') || ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail(i18n.t('t.theServer'))
    return Promise.reject(res)
  }
  if (res.data.resultCode != 200) {
    if (res.data.resultCode == 416) {
      localStorage['token'] = ''
      Dialog.confirm({
        className:'footDialog',
        title: i18n.t('t.automaticLogin'),
        message: `${i18n.t('t.theSystem')}<br/><div class="colorTheme size12 marTop10">（${i18n.t('t.noteAutonomous')}）</div>`,
        cancelButtonText:i18n.t('t.autonomousLogin'),
        confirmButtonText:i18n.t('t.assignedAccount'),
        theme:'round-button'
      })
      .then(() => {
        autoRegister({
          invitationCode:localStorage['invitationCode'] || ''
        }).then(res=>{
          localStorage['token'] = res.data.token
          Dialog.alert({
            title: i18n.t('t.systemAssigns'),
            message: `<div>${i18n.t('t.username')}：${res.data.loginName}</div><div class="marTop10">${i18n.t('t.password')}：${res.data.password}</div><div class="colorTheme size12 marTop10">（${i18n.t('t.noteRemember')}）</div>`,
            confirmButtonText:i18n.t('t.copy'),
            theme:'round-button'
          }).then(() => {
            let str = `${i18n.t('t.username')}：${res.data.loginName}\n${i18n.t('t.password')}：${res.data.password}`
            doCopy(str)
            location.reload()
          });
        })
      })
      .catch(() => {
        localStorage['lastHref'] = location.href
        router.push({ path: '/login' })
      });
    }else{
      if (res.data.message) Toast.fail(res.data.message)
    }
    return Promise.reject(res.data)
  }

  return res.data
})

export default axios
