<!--
	Created by xiaoqiang on 2023/10/28.
-->
<template>
  <van-popup
    v-model="payBool"
    :close-on-click-overlay="false"
    position="bottom"
    :style="{ height: payDialogBool?'80%':'40%'}">
      <van-row type="flex" align="center" justify="space-between" class="padding20 size16 borderButtE4">
        <van-row class="tle fontB">{{payDialogBool?$t('t.saveScreenshot'):$t('t.pay')}}</van-row>
        <van-icon name="close" @click="closeFn"/>
      </van-row>
      <div class="overflowY" v-if="payDialogBool">
        <img class="app" :src="payPNG" />
      </div>
      <van-form @submit="payFn" :show-error-message="false" v-else>
        <van-tabs v-model="ruleForm.payType"  @click="payTypeFn">
          <van-tab :title="$t('t.internetBank')" name="1" v-if="false"></van-tab>
          <van-tab :title="$t('t.wallet')" name="2" v-if="false"></van-tab>
          <van-tab :title="$t('t.scanQr')" name="3"></van-tab>
          <van-tab :title="$t('t.qris')" name="5" v-if="false"></van-tab>
          <!--<van-tab title="支付宝支付" name="7"></van-tab>-->
          <!--<van-tab v-if="codCover == 'Y'" :title="$t('t.cod')" name="6"></van-tab>-->
        </van-tabs>
        <van-field
          v-if="ruleForm.payType == 1 ||ruleForm.payType == 2 ||ruleForm.payType == 3"
          v-model="ruleForm.payName"
          name="payName"
          :label="$t('t.payName')"
          :placeholder="$t('t.placeholder7')"
          :rules="[{ required: true, message: $t('t.placeholder7')}]"
        />
        <van-row type="flex" align="center" class="paddLR15" v-if="ruleForm.payType == 1 || ruleForm.payType == 2">
          <van-row class="size14 width100">{{$t('t.bankcardCode')}}</van-row>
          <van-dropdown-menu class="flex1 dropdownBox">
            <van-dropdown-item ref="bankCodeRef" @change="bankCodeFn" v-model="ruleForm.bankCode" :options="ruleForm.payType == 1?bankCodeArr1:bankCodeArr2" >
              <template #title>
                <van-row :class="ruleForm.bankCode?'color24':'colorBB'" class="size14">{{bankCodeName}}</van-row>
              </template>
            </van-dropdown-item>
          </van-dropdown-menu>
        </van-row>
        <van-row class="padding20 marTop30" >
          <van-row v-if="codCover == 'Y' && ruleForm.payType == 6" class="textCenter">
            {{$t('t.currentshippingcosts')}}<span class="colorTheme size16 fontB">{{logisticsFees | wq_mFormat}}</span>
          </van-row>
          <van-row v-if="codCover == 'Y' && ruleForm.payType == 6" class="textCenter marBott30">
            {{$t('t.rememberCourier')}}
          </van-row>
          <van-button color="#e92d74" block native-type="submit">{{$t('t.confirmPayment')}}</van-button>
        </van-row>
      </van-form>

  </van-popup>

</template>

<script>
  import {payAfter,queryPay,getOrderDetail} from '../service/order'
  import { doCopy } from '../common/js/utils'
  import { getUserInfo,selectConfig } from '../service/user'
  export default {
    props:['orderNo','payBool','codCover','logisticsFees'],
    name: "pay",
    components: {},
    data() {
      return {
        ruleForm:{
          payName:'',
          payType:'5',
          pageUrl:'',
          bankCode:'',
          orderNo:''
        },
        bankCodeArr1:[{"value":"100591","text":"Bank BCA"},{"value":"100592","text":"Bank Mandiri"},{"value":"100593","text":"Permata Bank"},{"value":"100594","text":"Bank CIMB Niaga"},{"value":"100595","text":"Bank BII Maybank"},{"value":"100596","text":"Bank BRI"},{"value":"100597","text":"Bank Sahabat Sampoerna"},{"value":"100598","text":"Bank Syariah Indonesia"},{"value":"100599","text":"Bank Neo Commerc2"}],
        bankCodeArr2:[{"value":"100600","text":"OVO"},{"value":"100601","text":"DANA"},{"value":"100602","text":"LINKAJA"},{"value":"100603","text":"SHOPEEPAY"}],
        bankCodeName:this.$t('t.pleaseSelectCardCode'),
        payDialogBool:false,
        proTime:null,
        time1Min:null,
        currentTime1Min:36000,
        payPNG:'',
        orderNum:'',
        firstOrderReward:''
      };
    },
    watch: {
      orderNo:{
        handler(item) {
          let urlStr = `https://${window.location.host}/#/order-detail?id=${item}`
          this.ruleForm.pageUrl = urlStr
          this.ruleForm.orderNo = item
        },
        immediate: true,
        deep: true
      }
    },//监听
    async created(){
      const { data, resultCode } = await selectConfig()
      this.firstOrderReward = data.taskConfigParam.firstOrderReward
    },
    mounted() {
    },
    methods: {
      payTypeFn(val){
        this.bankCodeName = this.$t('t.pleaseSelectCardCode')
        this.ruleForm.bankCode = ''
      },
      bankCodeFn(val){
        let arr = this.ruleForm.payType == 1?this.bankCodeArr1:this.bankCodeArr2
        this.bankCodeName = arr.find(t=>t.value == val).text
      },
      async payFn(){
        if((this.ruleForm.payType == 1 || this.ruleForm.payType == 2) && !this.ruleForm.bankCode){
          this.$notify(this.$t('t.pleaseSelectCardCode'))
          return
        }
        this.$toast.loading({ message: this.$t('t.loading'), forbidClick: true });
        if(this.ruleForm.payType == 6){
          await payAfter({orderNo:this.ruleForm.orderNo}).then(res => {
            if (res.resultCode == 200) {
              let urlStr = `https://${window.location.host}/#/order-detail?id=${this.ruleForm.orderNo}`
              this.$emit('payBackFn')
              this.$toast(this.$t('t.saveSuccess'))
              window.location.href = urlStr
              this.$toast.clear()
            }
          })
        }else{
          await queryPay(this.ruleForm).then(res => {
            if (res.resultCode == 200) {
              if(this.ruleForm.payType == 5){
                this.payPNG = res.data
                this.payDialogBool = true
                this.progressTimer(this.ruleForm.orderNo)
              }else{
                this.$emit('payBackFn')
                this.$toast.clear()
                this.progressTimer(this.ruleForm.orderNo)
                window.location.href = res.data
              }
            }
          })
        }
      },
      progressTimer(id) {
        clearTimeout(this.proTime);
        this.proTime = null;
        getOrderDetail(id).then(res=>{
          if(res.data && res.data.payStatus == 1){
            clearTimeout(this.proTime);
            this.proTime = null;
            clearTimeout(this.time1Min);
            this.time1Min = null;
            this.payDialogBool = false
            getUserInfo().then(x=>{
              let userId = x.data.userId
              let orderNum = x.data.orderNum
              this.$dialog.confirm({
                message: `<div class="lineH1">
                            <div class="colorTheme size16 fontB paddBott20 borderButtE4">${this.$t('t.congratulationOrder')}</div>
                            <div class="textLeft tle color77">${this.$t('t.youWillReceive')}：</div>
                            <div class="textLeft lineH15 size12" style="display: ${orderNum == 1?'block':'none'}"><span class="fontB colorTheme marRight5 marBott3">※</span>${this.$t('t.aSuccessful1')}${this.firstOrderReward}${this.$t('t.aSuccessful2')}</div>
                            <div class="textLeft lineH15 size12"><span class="fontB colorTheme marRight5 marBott3">※</span>${this.$t('t.youCanShare1')}${this.firstOrderReward}${this.$t('t.youCanShare2')}</div>
                            <div class="textLeft lineH15 size12"><span class="fontB colorTheme marRight5 marBott3">※</span>${this.$t('t.youCanShareView')}</div>
                          </div>`,
                cancelButtonText:this.$t('t.goToPersonal'),
                confirmButtonText:this.$t('t.copyLink'),
                theme:'round-button'
              })
              .then(() => {
                let urlStr = `https://${window.location.host}/#/order-detail?id=${this.ruleForm.orderNo}`
                // doCopy(`https://www-dev-2.siweipay.com/#/product/${res.data.newBeeMallOrderItemVOS[0].goodsId}?shareId=${userId}`)
                this.$emit('payBackFn')
                this.$toast.clear()
                window.location.href = urlStr
              })
              .catch(() => {
                this.$emit('payBackFn')
                this.$toast.clear()
                this.$router.push({ path: '/user' })
              });
              return
            })
          }
          if(this.currentTime1Min<=0){//询价轮询60次 180秒还未成功
            clearTimeout(this.proTime);
            this.proTime = null;
            clearTimeout(this.time1Min);
            this.time1Min = null;
            this.payDialogBool = false
          }
        })
        this.proTime = setTimeout(()=>{
          this.progressTimer(id);
        },2000)
      },
      time(){
        if (this.currentTime1Min == 0) {
          clearTimeout(this.time1Min);
          this.time1Min = null;
        } else {
          this.currentTime1Min--
          this.time1Min = setTimeout(()=>{
            this.time();
          },1000)
        }
      },
      closeFn(){
        if(this.payDialogBool){
          this.$dialog.confirm({
            title:this.$t('t.tip'),
            message:this.$t('t.payCloseTip'),
            confirmButtonText: this.$t('t.sure'),
            cancelButtonText: this.$t('t.cancel'),
            customClass:'size14',
            type:'warning'
          }).then(() => {
            clearTimeout(this.proTime);
            this.proTime = null;
            clearTimeout(this.time1Min);
            this.time1Min = null;
            this.payDialogBool = false
            this.$emit('memberBackFn')
          })
        }else{
          this.$dialog.confirm({
            message: this.$t('t.confirmCancelPayment'),
            beforeClose:this.beforeClose,
          }).then((action, done) => {

          })
        }
      },
      beforeClose(action, done) {
        if (action === 'confirm') {
          this.$emit('payBackFn')
          clearTimeout(this.time1Min);
          this.time1Min = null;
          clearTimeout(this.proTime);
          this.proTime = null;
          setTimeout(done, 100);
        } else {
          done();
        }
      }
    },
    //生命周期 - 更新之后",数据模型已更新,html模板已更新
    updated() {

    },
    // 页面销毁前
    beforeDestroy(){
      clearTimeout(this.time1Min);
      this.time1Min = null;
      clearTimeout(this.proTime);
      this.proTime = null;
    },
  }
</script>

<style scoped>
  .width100{width: 90px}
  .dropdownBox /deep/ .van-dropdown-menu__bar{
    box-shadow: none;
  }
  .dropdownBox /deep/ .van-dropdown-menu__item{
    justify-content: flex-start;
  }
  .posiBott{
    position: absolute;
    bottom: 10px;
  }
  .dropdownBox /deep/ .van-dropdown-item__option{
    /*padding-left: 100px;*/
    text-align: center;
  }
  .dropdownBox /deep/ .van-cell__value{
    flex: none;
  }
</style>
